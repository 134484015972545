import Vue from 'vue';
import '@/assets/scss/vue-tables.scss';
import { get, post } from '@/services/api';
import {
  creditOrderReceived,
  creditOrderAccept,
  creditOrderReceivedDistributor
} from '../../../services/endpoint.json';
import { Pagination } from '@/utils/pagination';
import {CREDIT_ORDERS_STATUS, ORDER_CREDIT_STATUS} from '@/constants';
import { mapGetters } from 'vuex';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import CreditsView from '@/components/common/CreditsView';

export default Vue.extend({
  name: 'credit-history',
  components: {
    CurrencyPriceView,
    CreditsView
  },
  props: {
    type: { default: 'dealers' },
  },
  data: () => ({
    CREDIT_ORDERS_STATUS: CREDIT_ORDERS_STATUS,
    fields: [
      {key: 'id', label: 'Order #'},
      {key: 'user', label: 'User'},
      'description', 'status',
      {key: 'credits', label: 'Credits ordered', class: 'text-right'},
      {key: 'totalReceived', label: 'Credits received', class: 'text-right'},
      {key: 'price', label: 'Total Price', class: 'text-right'},
      {key: 'totalPaid', label: 'Total Paid', class: 'text-right'},
      {key: 'totalPending', label: 'Total Pending', class: 'text-right'},
      {key: 'discount', class: 'text-right'},
      {key: 'createdAt', label: 'Date'},
      'actions'
    ],
    pagination: new Pagination(),
    users: [],
    filter: { userId: null, status: null }
  }),
  mounted() {
    this.getData();
    this.loadClients();
  },
  watch: {
    type: function () {
      this.getData();
    },
    filter: {
      deep: true,
      handler() {
        this.getData();
      }
    }
  },
  methods: {
    async getData() {
      const urlEndpoint = (this.type.toString() === 'dealers') ? creditOrderReceived : creditOrderReceivedDistributor;
      let { data } = await get(`${urlEndpoint}?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}&id=${this.filter.userId}`, null, true);
      this.pagination.total = data.count;
      this.pagination.setTableData(data.data);
    },
    paginate(page) {
      this.pagination.currentPage = page;
      this.getData();
    },
    acceptOrder(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Do you want to accept the offer?',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then(async res => {
          if (res.isConfirmed) {
            const { data } = await post(creditOrderAccept, { id }, true);
            this.$router.push({
              name: 'credit-order-detail',
              params: { id: id }
            });
          }
        });
    },
    showDetailOrder(id) {
      this.$router.push({
        name: 'credit-order-detail',
        params: { id: id }
      });
    },
    async loadClients() {
      const { data } = await get('user/distributor,dealer,subdealer', null, true);
      this.users = data;
    },
    getColor(status) {
      if (!!status) {
        return ORDER_CREDIT_STATUS().find(x => x.text === status).color
      } else
        return '#000000'
    },
  },
  computed: mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits', 'currencyToUser'])
});

